import React, { useState, useRef } from 'react';
import GoogleAnalytics from './Helpers/GoogleAnalytics';
import { IconX, IconCheck } from '@tabler/icons-react';
import { Modal } from '@mantine/core';
import Fade from 'react-reveal/Fade';
import { API_URL } from '../Library/index';
import Validate from 'validate.js';
import DynamicIcon from './Helpers/DynamicIcon';
import PulseLoader from "react-spinners/PulseLoader";

export default function AndroidWaitlistModal(props) {
    const [showModal, setShowModal] = useState(false);
    const email = useRef(null);
    const name = useRef(null);
    const [submittedName, setSubmittedName] = useState(null);
    const [emailMessage, setEmailMessage] = useState(null);
    const [nameMessage, setNameMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);
    const [success, setSuccess] = useState(false);

    const constraints = {
        email: {
            presence: {
                allowEmpty: false
            },
            email: {
                message: "Please enter valid email"
            }
        },
        name: {
            presence: {
                allowEmpty: false
            }
        }
    };

    const handleModalOpen = () => {
        let objGA = {
            category: "Waitlist_web",
            action: "Waitlist_web_open_modal"
        };

        GoogleAnalytics('', objGA);
        setShowModal(true);
    }

    function handleOnKeyPress(e) {
        if (e && e.charCode === 13) handleSubscribe()
        else {
            setEmailMessage(null)
            setNameMessage(null)
        }
    }

    async function handleSubscribe() {
        setLoading(true);

        let check = Validate({
            email: email?.current?.value,
            name: name?.current?.value
        }, constraints);

        if (check) {
            setEmailMessage(check?.email ? (check.email.length > 1 ? "Required" : "Enter valid email") : null);
            setNameMessage(check?.name ? "Required" : null);
            setLoading(false);
            return;
        }

        let source = JSON.parse(localStorage.getItem('aa-src-v1') || '{}') || {}
        setSubmittedName(name?.current?.value);

        var query = {
            query: `
                mutation($joinWaitListInput: WaitListItem!, $sendUpdateInput: UpdateInput!, $nodeAnalyticsInput: UpdateNodeAnalyticsInput!) {
                    joinWaitList(input: $joinWaitListInput)
                    sendUpdate(input: $sendUpdateInput)
                    updateNodeAnalytics(input: $nodeAnalyticsInput)
                }
            `,
            variables: {
                joinWaitListInput: {
                    name: name?.current?.value,
                    email: email?.current?.value,
                    beta_user: false,
                    listType: 'android waitlist',
                    source: source?.source,
                    source_desc: source?.source_desc
                },
                sendUpdateInput: {
                    subject: 'Arvel: +1 Android Waitlist',
                    userEmail: email?.current?.value,
                    message: name?.current?.value + ' joined Android Waitlist. Source: ' + source?.source + '. Source description: ' + source?.source_desc
                },
                nodeAnalyticsInput: {
                    id: 59,
                    type: 'view',
                    source: source?.source,
                    source_desc: source?.source_desc
                }
            }
        }

        try {
            const resp = await fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(query)
            }).then((response) => response.json())

            if (resp?.data?.joinWaitList) {
                setSuccess(true);
                setLoading(false);

                let objGA = {
                    category: "Waitlist_web",
                    action: "Waitlist_web_joined"
                };

                GoogleAnalytics('', objGA);
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleCopyATlink = () => {
        navigator.clipboard.writeText('https://arvel.app/?uri=ap-ep101i706');
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 10000);

        GoogleAnalytics('', {
            category: "Waitlist_web",
            action: "Waitlist_web_link_copied"
        });
    }

    const _renderModalJoinBtn = () => {
        if (loading) return <PulseLoader color={'#fff'} size={5} />
        else return 'Join waitlist'
    }

    function handleAnalytics(type) {
        let objGA = {
            category: "Waitlist_modal",
            action: ""
        };

        if (type === 'IG') objGA.action = 'Waitlist_joined_IG';
        else if (type === 'LN') objGA.action = 'Waitlist_joined_LN';
        else if (type === 'X') objGA.action = 'Waitlist_joined_X';

        GoogleAnalytics('', objGA);
    }

    const _renderSuccessMsg = () => {
        let nameParts = submittedName?.split(" ");
        let firstName = nameParts[0];
        firstName = firstName?.charAt(0)?.toUpperCase() + firstName?.slice(1);

        return <p style={{ lineHeight: '160%', textAlign: 'center' }}>You're on the waitlist, <span style={{ fontWeight: 600 }}>{' ' + firstName}</span>! Hang tight, we're launching Android app soon 🚀</p>
    }

    const _renderModalContent = () => {
        if (success) return <div className='wl-success-div'>
            <DynamicIcon type="messageSentSuccess" width={'140'} height={'140'} />
            {_renderSuccessMsg()}
            <button className='wn-subscribe wn-lc' onClick={handleCopyATlink} style={{ width: '70%', backgroundColor: (linkCopied ? 'rgb(85 196 103)' : 'rgb(63, 163, 240)') }}>
                {linkCopied && <Fade><IconCheck stroke={3} width='22' color='#fff' /></Fade>}
                {linkCopied ? 'Link copied' : 'Invite friend'}
            </button>
            <div className='sm-btns-div'>
                <div className='smb-header'>
                    <div className='smbdh-line' />
                    <p>find us on:</p>
                </div>
                <div className='container'>
                    <a target="_blank" rel="noopener noreferrer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} href="https://www.instagram.com/arvel.app/" onClick={() => handleAnalytics('IG')}>
                        <img alt="icon-bl" loading='lazy' style={{ width: 31, height: 31 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/instagram.svg' />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/arvel" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0 12px 0 11px' }} onClick={() => handleAnalytics('LN')}>
                        <img alt="icon-bl" loading='lazy' style={{ width: 30, height: 30 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/linkedin.svg' />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/arvel_app" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleAnalytics('X')}>
                        <img alt="icon-bl" loading='lazy' style={{ width: 26, height: 26 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/twitter.svg' />
                    </a>
                </div>
            </div>
        </div>
        else return <div>
            <p className='ctm-hdr-body'>Join waitlist & know when we launch Android app 🚀</p>
            <div className={(!nameMessage ? 'hth-input' : 'hth-input error')}>
                <input name="name"
                    ref={name}
                    type="name"
                    className='input'
                    onKeyPress={handleOnKeyPress}
                    placeholder='Name' />
                <span className="helper-txt ht-input">{nameMessage}</span>
            </div>
            <div className={(!emailMessage ? 'hth-input' : 'hth-input error')}>
                <input name="email"
                    ref={email}
                    type="email"
                    className='input'
                    onKeyPress={handleOnKeyPress}
                    placeholder='Email' />
                <span className="helper-txt ht-input">{emailMessage}</span>
            </div>
            <button style={{ width: '70%', height: 40, fontWeight: 600, marginBottom: 20, backgroundColor: 'rgb(63, 163, 240)' }} className='wn-subscribe' onClick={handleSubscribe} disabled={loading}>{_renderModalJoinBtn()}</button>
        </div>
    }

    return (
        <>
            <button disabled={success}
                style={{ position: 'relative' }}
                onClick={handleModalOpen}>
                <img
                    src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/download-android.png'
                    alt="img"
                    loading="lazy"
                    className='download-ios-img'
                />
                <p className='awm-cs-txt'>join waitlist</p>
            </button>
            <Modal
                centered={true}
                withCloseButton={false}
                opened={showModal}
                onClose={() => setShowModal(false)}
                className='customer-testimonial-modal travel-passport-modal'>
                <button className='ct-close-btn' onClick={() => setShowModal(false)}>
                    <IconX stroke={2.5} width='20' height='20' color='rgb(217, 52, 113)' />
                </button>
                {_renderModalContent()}
            </Modal>
        </>
    );
}