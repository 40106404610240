import React from 'react';
import './Components.css';
import GoogleAnalytics from '../Components/Helpers/GoogleAnalytics';
import { IconCopyright } from '@tabler/icons-react';

export default function Footer(props) {
    const { windowWidth } = props;

    function handleAnalytics(type) {
        let objGA = {
            category: "Main_Page",
            action: ""
        };

        if (type === 'IG') objGA.action = 'Footer_IG';
        else if (type === 'LN') objGA.action = 'Footer_LN';
        else if (type === 'YT') objGA.action = 'Footer_YouTube';
        else if (type === 'X') objGA.action = 'Footer_X';
        else if (type === 'DI') objGA.action = 'Footer_delete_instagram';
        else if (type === 'contact') objGA.action = 'Footer_contact_us';
        else if (type === 'privacy') objGA.action = 'Footer_privacy';
        else if (type === 'terms') objGA.action = 'Footer_terms';
        else if (type === 'story') objGA.action = 'Footer_our_story';

        GoogleAnalytics('', objGA);
    }

    function _renderDevice() {
        if (windowWidth < 600) {
            return <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='copywrite'><IconCopyright stroke={1.2} width='20' style={{ marginRight: 3 }} /> 2025 Arvel</div>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/arvel.app/" className="page-link" onClick={() => handleAnalytics('IG')}><img alt="icon-bl" loading='lazy' style={{ width: 21, height: 21 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/instagram.svg' /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCKnunO7RJLlhfJwfxRYDVLQ" className="page-link" onClick={() => handleAnalytics('YT')}><img alt="icon-bl" loading='lazy' style={{ width: 21, height: 21 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/youtube.svg' /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/arvel" className="page-link" onClick={() => handleAnalytics('LN')}><img alt="icon-bl" loading='lazy' style={{ width: 21, height: 21 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/linkedin.svg' /></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/arvel_app" className="page-link" onClick={() => handleAnalytics('X')}><img alt="icon-bl" loading='lazy' style={{ width: 19, height: 19 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/twitter.svg' /></a>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <a href="/our-story" className="page-link" onClick={() => handleAnalytics('story')}>Our story</a>
                    <a href="/contact" className="page-link" onClick={() => handleAnalytics('contact')}>Contact us</a>
                    <a href="/privacy" className="page-link" onClick={() => handleAnalytics('privacy')}>Privacy</a>
                    <a href="/terms" className="page-link" onClick={() => handleAnalytics('terms')}>Terms</a>
                </div>
            </>
        } else {
            return <div className="ftr-body">
                <div className='web-clm-1'>
                    <img alt="logo" loading='lazy' src='https://s3.us-east-2.amazonaws.com/arvel.photos/logos/logo_transparent_black.png' />
                    <div className='cr-container'><IconCopyright stroke={1.2} width='20' />2025 Arvel, Inc.</div>
                </div>
                <div className='web-clm-2'>
                    <div className='wc-2-1'>
                        <h3>Find us</h3>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/arvel.app/" className="page-link" onClick={() => handleAnalytics('IG')}>
                            <img alt="icon-bl" loading='lazy' style={{ width: 19, height: 19, marginRight: 2 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/instagram.svg' />Instagram
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/arvel" className="page-link" onClick={() => handleAnalytics('LN')}>
                            <img alt="icon-bl" loading='lazy' style={{ width: 19, height: 19, marginRight: 2 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/linkedin.svg' />LinkedIn
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCKnunO7RJLlhfJwfxRYDVLQ" className="page-link" onClick={() => handleAnalytics('YT')}>
                            <img alt="icon-bl" loading='lazy' style={{ width: 19, height: 19, marginRight: 2 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/youtube.svg' />Youtube
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/arvel_app" className="page-link" onClick={() => handleAnalytics('X')}>
                            <img alt="icon-bl" loading='lazy' style={{ width: 17, height: 17, marginRight: 4 }} src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/icons/twitter.svg' />X
                        </a>
                    </div>

                    <div className='wc-2-1'>
                        <h3>Help center</h3>
                        <a href="/our-story" className="page-link" onClick={() => handleAnalytics('story')}>Our story</a>
                        <a href="/contact" className="page-link" onClick={() => handleAnalytics('contact')}>Contact us</a>
                    </div>

                    <div className='wc-2-1'>
                        <h3>Resources</h3>
                        <a href="/privacy" className="page-link" onClick={() => handleAnalytics('privacy')}>Privacy policy</a>
                        <a href="/terms" className="page-link" onClick={() => handleAnalytics('terms')}>Terms of use</a>
                    </div>
                </div>
            </div>
        }
    }

    return (
        <>
            <nav className="footer">
                {_renderDevice()}
            </nav>
        </>
    );
}