import React, { useEffect, useState } from 'react';
import './Main.css';
import '../../Components/AndroidWaitlistModal.css';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import DefineTrafficSource from './../../Components/Helpers/DefineTrafficSource';
import LoadingPage from '../../Components/LoadingPage';
import AndroidWaitlistModal from '../../Components/AndroidWaitlistModal';

export default function Main() {
    GoogleAnalytics('/main', {});

    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [slideNum, setSlideNum] = useState(0);
    const [fadeClass, setFadeClass] = useState('');

    const screensList = [
        'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/iphone-screenshot-1.png',
        'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/iphone-screenshot-2.png',
        'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/iphone-screenshot-3.png',
        'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/iphone-screenshot-4.png',
        'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/iphone-screenshot-5.2.png'
    ];

    useEffect(() => {
        const isMobile = window.matchMedia('(max-width: 768px)').matches;

        const imageUrls = isMobile
            ? [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/logos/mob_app_user_home_header_3.png',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/download-ios.png',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/download-android.png'
            ]
            : [
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/google-pixel-8-frame.png',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/iphone-15-frame-filled.png',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/logos/mob_app_user_home_header_3.png',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/download-ios.png',
                'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/download-android.png',
                ...screensList
            ];

        let loadedCount = 0;
        document.body.classList.add('page-is-loading');

        const checkIfAllLoaded = () => {
            loadedCount++;
            if (loadedCount === imageUrls.length) {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        };

        imageUrls.forEach((url) => {
            const img = new Image();
            img.src = url;
            if (img.complete) {
                checkIfAllLoaded(); // Already cached
            } else {
                img.onload = checkIfAllLoaded;
                img.onerror = checkIfAllLoaded; // Handle errors gracefully
            }
        });

        return () => document.body.classList.remove('page-is-loading');
    }, []);

    useEffect(() => {
        DefineTrafficSource();
    }, []);

    useEffect(() => {
        const slideInterval = setInterval(() => {
            renderSlide();
        }, 4000);

        return () => clearInterval(slideInterval);
    }, [slideNum]);

    const renderSlide = () => {
        setFadeClass(''); // Reset the fade class to trigger the transition again
        setTimeout(() => {
            setFadeClass('visible'); // Apply the fade-in class
        }, 100); // Delay before applying the fade-in class
        setSlideNum((prevSlideNum) => (prevSlideNum === 4 ? 0 : prevSlideNum + 1));
    };

    function handleAnalytics(type) {
        let objGA = {
            category: "Main_Page",
            action: ""
        };

        if (type === 'IG') objGA.action = 'Footer_IG';
        else if (type === 'LN') objGA.action = 'Footer_LN';
        else if (type === 'YT') objGA.action = 'Footer_YouTube';
        else if (type === 'X') objGA.action = 'Footer_X';
        else if (type === 'DI') objGA.action = 'Footer_delete_instagram';
        else if (type === 'delete-IG') objGA.action = 'Footer_download_IG';
        else if (type === 'ios') objGA.action = 'Main_download_ios';
        else if (type === 'android') objGA.action = 'Main_download_android';
        else if (type === 'contact') objGA.action = 'Footer_contact_us';
        else if (type === 'privacy') objGA.action = 'Footer_privacy';
        else if (type === 'terms') objGA.action = 'Footer_terms';
        else if (type === 'story') objGA.action = 'Footer_our_story';
        else if (type === 'story') objGA.action = 'Footer_explore';

        GoogleAnalytics('', objGA);
    }

    const _renderContent = () => {
        if (!isPageLoaded) return <LoadingPage />;
        else return <>
            <div className='body-container'>
                <div className='column-left'>
                    <img
                        src={'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/google-pixel-8-frame.png'}
                        alt="img"
                        loading="lazy"
                        className='android-frame'
                    />
                    <img
                        src={'https://s3.us-east-2.amazonaws.com/arvel.photos/Web/iphone-15-frame-filled.png'}
                        alt="img"
                        loading="lazy"
                        className='iphone-frame-filled'
                    />
                    {
                        screensList.map((screenshot, index) => {
                            return (
                                <img
                                    key={index}
                                    src={screenshot}
                                    alt="img"
                                    loading="lazy"
                                    className={`iphone-screenshot ${index === slideNum ? fadeClass : ''}`}
                                    style={{ opacity: index === slideNum ? 1 : 0 }} // Hide non-active slides
                                />
                            );
                        })
                    }
                </div>
                <div className='column-right'>
                    <img
                        src='https://s3.us-east-2.amazonaws.com/arvel.photos/logos/mob_app_user_home_header_3.png'
                        alt="img"
                        loading="lazy"
                        className='company-logo-img'
                    />
                    <h3 className='one-liner'>Track your travels and explore new places.</h3>
                    <p className='get-app-text'>Get the app.</p>
                    <div className='get-app-btns-container'>
                        <a rel="noopener noreferrer" href="/" onClick={() => handleAnalytics('ios')}>
                            <img
                                src='https://s3.us-east-2.amazonaws.com/arvel.photos/Web/download-ios.png'
                                alt="img"
                                loading="lazy"
                                className='download-ios-img'
                            />
                        </a>
                        <AndroidWaitlistModal />
                    </div>
                </div>
            </div>
            <div className='main-footer-container'>
                <div className='line-one'>
                    <a rel="noopener noreferrer" href="/our-story" className="page-link" onClick={() => handleAnalytics('story')}>
                        <p className='footer-text'>About us</p>
                    </a>
                    <a rel="noopener noreferrer" href="/privacy" className="page-link" onClick={() => handleAnalytics('privacy')}>
                        <p className='footer-text'>Privacy policy</p>
                    </a>
                    <a rel="noopener noreferrer" href="/terms" className="page-link" onClick={() => handleAnalytics('terms')}>
                        <p className='footer-text'>Terms of use</p>
                    </a>
                    <a rel="noopener noreferrer" href="/contact" className="page-link" onClick={() => handleAnalytics('contact')}>
                        <p className='footer-text'>Contact us</p>
                    </a>
                    <a rel="noopener noreferrer" href="https://arvel.app/experiences/worldwide" className="page-link" onClick={() => handleAnalytics('explore')}>
                        <p className='footer-text'>Explore the world</p>
                    </a>
                    <a rel="noopener noreferrer" href="/delete-instagram" className="page-link" onClick={() => handleAnalytics('delete-IG')}>
                        <p className='footer-text'>Delete instagram</p>
                    </a>
                </div>

                <div className='line-two'>
                    <div className='socials-container'>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/arvel.app/" className="page-link" onClick={() => handleAnalytics('IG')}>
                            <p className='footer-text'>Instagram</p>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCKnunO7RJLlhfJwfxRYDVLQ" className="page-link" onClick={() => handleAnalytics('YT')}>
                            <p className='footer-text'>Youtube</p>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/arvel" className="page-link" onClick={() => handleAnalytics('LN')}>
                            <p className='footer-text'>LinkedIn</p>
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/arvel_app" className="page-link" onClick={() => handleAnalytics('X')}>
                            <p className='footer-text'>X</p>
                        </a>
                    </div>
                    <p className='footer-text'>© 2025 Arvel Inc.</p>
                </div>
            </div>
        </>
    };

    return (
        <div id="main" className="main main-page">
            {_renderContent()}
        </div>
    );
};