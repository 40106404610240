import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Main from './Pages/main/Main';
import Contact from './Pages/contact/Contact';
import NotFound from './Pages/404/404';
import Terms from './Pages/terms/Terms';
import Privacy from './Pages/privacy/Privacy';
import OurStory from './Pages/about-us/OurStory';
import DeleteInstagram from './Pages/delete-instagram/DeleteInstagram';
import PrivacyChoices from './Pages/privacy-choices/PrivacyChoices';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default function App() {

  useEffect(() => {
    (function (h, o, t, j, a, r) {
      h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
      h._hjSettings = { hjid: 3013434, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script'); r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, [])

  return (
    <Router>
      <Switch>
        <Route path="/our-story" render={(props) => <OurStory {...props} pageName="Our story" />} />
        <Route path="/privacy-choices" render={(props) => <PrivacyChoices {...props} pageName="Privacy choices" />} />
        <Route path="/contact" render={(props) => <Contact {...props} pageName="Contact" />} />
        <Route path="/privacy" render={(props) => <Privacy {...props} pageName="Privacy Policy" />} />
        <Route path="/terms" render={(props) => <Terms {...props} pageName="Terms of Use" />} />
        <Route path="/delete-instagram" render={(props) => <DeleteInstagram {...props} pageName="Delete Instagram" />} />
        <Route exact path="/" render={(props) => <Main {...props} pageName="Main" />} />
        <Route path="*" render={(props) => <NotFound {...props} pageName="404 Not Found" />} />
      </Switch>
    </Router>
  );
}

ReactDOM.render(
  <App></App>,
  document.getElementById('root')
);