import React, { useEffect, useState } from 'react';
import './PrivacyChoices.css';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import GoogleAnalytics from '../../Components/Helpers/GoogleAnalytics';
import Fade from 'react-reveal/Fade';
import LoadingPage from '../../Components/LoadingPage';

export default function PrivacyChoices() {
    GoogleAnalytics('/privacy-choices', {});

    const windowWidth = window.innerWidth;
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    useEffect(() => {
        function handlePageLoad() {
            if (document.readyState === "complete") {
                setIsPageLoaded(true);
                document.body.classList.remove('page-is-loading');
            }
        }

        document.body.classList.add('page-is-loading');

        handlePageLoad();
        document.addEventListener("readystatechange", handlePageLoad);

        return () => {
            document.removeEventListener("readystatechange", handlePageLoad);
            document.body.classList.remove('page-is-loading');
        };
    }, []);

    const _renderLoadingPage = () => {
        if (!isPageLoaded) return <LoadingPage />
    };

    return (
        <div className='terms-privacy-main'>
            {_renderLoadingPage()}
            <Header pageName='User privacy choices - Arvel Help Center' windowWidth={windowWidth} />
            <Fade>
                <div className='display-flex terms-privacy-container'>
                    <h1 className='os-header'>User privacy choices</h1>
                    <p className='os-header-footer'>Last updated: March 14, 2025</p>

                    <p className='os-response'>At Arvel, we value your privacy and are committed to giving you control over your personal data.
                        This page explains the data we collect, how we use it, and the choices available to you.</p>

                    <h3 className='os-question'>Data We Collect</h3>
                    <p className='os-response'>We collect certain types of data to provide and improve our services. This may include:</p>
                    <ul className='os-response-ul'>
                        <li className='os-response-li'><b>Personal Information:</b> Email address, username, and profile details.</li>
                        <li className='os-response-li'><b>Usage Data:</b> Interactions within the app, preferences, and activity logs.</li>
                    </ul>

                    <h3 className='os-question'>How We Use Your Data</h3>
                    <p className='os-response'>We collect certain types of data to provide and improve our services. This may include:</p>
                    <ul className='os-response-ul'>
                        <li className='os-response-li'>Provide, personalize, and improve your experience on Arvel.</li>
                        <li className='os-response-li'>Enhance app functionality and offer relevant recommendations.</li>
                        <li className='os-response-li'>Ensure the security and integrity of our platform.</li>
                        <li className='os-response-li'>Comply with legal obligations and prevent misuse.</li>
                    </ul>

                    <h3 className='os-question'>Your Privacy Choices</h3>
                    <p className='os-response'>You have control over your data in the following ways:</p>
                    <ul className='os-response-ul'>
                        <li className='os-response-li'><b>Manage Your Data:</b> Update or delete your profile information in the app settings.</li>
                        <li className='os-response-li'><b>Opt Out of Tracking:</b> Adjust privacy settings to limit data collection.</li>
                        <li className='os-response-li'><b>Request Data Deletion:</b> Contact us at <a style={{ color: 'rgb(63, 163, 240)' }} href="mailto:info@arvel.app">info@arvel.app</a> to request account and data removal.</li>
                    </ul>

                    <h3 className='os-question'>Contact Us</h3>
                    <p className='os-response'>If you have any questions about your privacy choices, please reach out to us at <a style={{ color: 'rgb(63, 163, 240)' }} href="mailto:info@arvel.app">info@arvel.app</a>.</p>
                    <p className='os-response'>For more details, see our full <a style={{ color: 'rgb(63, 163, 240)' }} href="/privacy">Privacy Policy</a>.</p>
                </div>
            </Fade>
            <Footer windowWidth={windowWidth} />
        </div>
    );
}