import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import './Components.css';
import GoogleAnalytics from '../Components/Helpers/GoogleAnalytics';

export default function Header(props) {
    const { pageName } = props;

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    function handleAnalytics(type) {
        let objGA = {
            category: "Main_Page",
            action: ""
        };

        if (type === 'home') objGA.action = 'Main_header_home';

        GoogleAnalytics('', objGA);
    };

    function _renderHelmet() {
        if (pageName === 'Main') return;
        else return <Helmet>
            <title>{pageName}</title>
        </Helmet>
    };

    return (
        <>
            {_renderHelmet()}
            <nav className={`header ${scrollY > 50 ? 'header-scrolled' : ''}`}>
                <div className="display-flex hdr-body">
                    <a href="/" className="header-logo" onClick={() => handleAnalytics('home')}>
                        <img alt="Logo" loading='lazy' src='https://s3.us-east-2.amazonaws.com/arvel.photos/logos/Arvel-logo_RGB_V4.png' />
                    </a>
                </div>
            </nav>
        </>
    );
};